<template>
	<div class='mb-3 splash-msg fade show'>
		<h2 class='text-success'>{{title || 'Success!'}}</h2>
		<p class='text-success pt-2'>{{message}}</p>
	</div>
</template>

<style lang='scss' scoped>
	.splash-msg{
		font-size: 1.3rem;
	}
</style>

<script>
export default {
	name: "SuccessSplash",
	props: {
		title: String,
		message: String
	}
};
</script>