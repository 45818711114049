<template>
	<div>
		<Race />

		<hr class='mt-4 mb-4'/>

		<ErrorMessage v-if='error.length > 0' :message='error' />

		<div class='mt-3'>
			<button class='btn btn-secondary' @click='last_race' v-if='!is_first_race'>Previous Ballot</button>
			<button class='btn btn-primary float-right' @click='next_race' v-if='!is_last_race && !this.vote_state'>Next Ballot</button>
			<button class='btn btn-success float-right' @click='next_race' v-if='is_last_race && !this.vote_state'>Review Votes</button>
			<button class='btn btn-success float-right' @click='next_race' v-if='this.vote_state'>Back to Review</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";
import Race from "@/components/Vote/Race";

import { SET_RACE } from "@/store/actions.type";

export default {
	name: 'Voting',
	components: {
		Race,
		ErrorMessage
	},
	data(){
		return {
			error: ''
		};
	},
	computed: {
		is_first_race(){
			return (this.race_step == 0);
		},
		is_last_race(){
			return ((this.race_step+1) == this.races.length);
		},
		...mapGetters(['election', 'races', 'current_race', 'current_votes', 'voter', 'race_step', 'vote_state'])
	},
	methods: {
		last_race(){
			store.dispatch(SET_RACE, (this.race_step-1));
		},
		next_race(){
			this.error = '';
			if(!this.current_race.allow_abstain){
				var not_voted = true;
				if(this.current_race.race_type == "ranked") {
					for(var i = 0;i<this.current_votes.length;i++){
						if(this.current_votes[i] != "") not_voted = false; 
					}
				} else if(this.current_votes.length > 0){
					not_voted = false;
				}

				if(not_voted) {
					this.error = "Please make a selection to continue.";
					return;
				}
			}
			if(this.is_last_race || this.vote_state){
				// Store race
				this.$emit('next');
			} else {
				store.dispatch(SET_RACE, (this.race_step+1));
			}
		},
		back(){
			//@TODO wipe session
			this.$emit('back');
		}
	}
}
</script>