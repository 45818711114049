<template>
	<div>
		<EnterCode v-if='state == "code"' @next='view_preamble'/>
		<Header v-if='state != "code"'/>
		<div class='row justify-content-center' v-if='state != "code"' >
			<div class='card col-md-8 mb-0'>
				<div class='card-body'>
					<Preamble v-if='state == "preamble"' @back='view_code' @next='view_voting'/>

					<Voting v-if='state == "vote" && !live_view' @back='view_code' @next='view_review'/>
					<LiveVoting v-if='state == "vote" && live_view' @back='view_code' @next='view_review'/>

					<Review v-if='state == "review"' @back='view_voting' @next='view_finished' @start_over='view_preamble'/>
				</div>
			</div>
		</div>
		<div class='row' v-if='!["code", "preamble"].includes(state)'>
			<a href='#' class='log-out mt-4 mb-4 w-100 text-center' @click.prevent='view_code'>Done Voting? Log Out</a>
		</div>
	</div>
</template>

<style scoped lang='scss'>
	.log-out{
		color: #DC1F26;

		&:hover{
			color: #58a8ff;
		}
	}
</style>

<script>
	import store from "@/store/";
	import { mapGetters } from 'vuex';
	import Header from '@/components/Layout/Header';

	import { SET_STATE } from "@/store/mutations.type";

	import Message from "@/components/General/Message";

	import EnterCode from "@/views/Vote/states/EnterCode";
	import Review from "@/views/Vote/states/Review";
	import Voting from "@/views/Vote/states/Voting";
	import LiveVoting from "@/views/Vote/states/LiveVoting";
	import Preamble from "@/views/Vote/states/Preamble";

	// Language / Localization
	export default {
		name: "Vote",
		components: {
			Message,
			Voting,
			LiveVoting,
			Review,
			EnterCode,
			Preamble,
			Header
		},
		props: {
			
		},
		data() {
			return {
				state: 'code'
			}
		},
		computed: {
			live_view(){
				try{
					if(this.election.type == "meeting") return true;
					else return false;
				}catch(e){
					return false;
				}
			},
			...mapGetters(['election', 'voter'])
		},
		methods: {
			view_code(){
				// @TODO -- Clear code & voter data
				this.state = 'code';
				store.commit(SET_STATE, false);
			},
			view_preamble(){
				this.state = 'preamble';
				store.commit(SET_STATE, false);
			},
			view_voting(){
				this.state = 'vote';
			},
			view_review(){
				this.state = 'review';
				store.commit(SET_STATE, true);
			},
			view_finished(){
				this.$router.push({ name: 'voted', query: { time: Date.now() }});
			},
			logout(){
				this.state = 'code';
				store.commit(SET_STATE, false);
			}
		}
	}
</script>