<template>
	<div>
		<h4 class="card-title">Review Votes</h4>
		<h6 class="card-subtitle mb-4">Review and correct any issues with your selections.</h6>

		<hr class='mt-4 mb-4'/>

		<div class='row mb-4' v-for='(race, index) in races'>
			<div class='col-md-5'>
				<h6 class="mb-1">Title: <b>{{race.title}}</b></h6>
				<h6 class="mb-1">Type: <b style='text-transform: Capitalize;'>{{race.race_type}}</b></h6>
				<h6 class="mb-1" v-if='race.race_type != "referendum"'>Position: <b>{{race.position}}</b></h6>
				<h6 class="mb-1"><span v-if='race.race_type != "referendum"'>Description</span><span v-if='race.race_type == "referendum"'>Referendum Question</span>: <b>{{race.description}}</b></h6>
			</div>
			<div class='col-md-7'>
				<div class="widget-profile__list"> 
					<div class="media" :class='{"ranked-media": race.race_type == "ranked"}' v-for='(selection, rank) in votes[index]' v-if='selection != ""'>
						<div class="avatar-char" v-if='!get_image(index, rank)'><i class="zwicon-user-circle"></i></div>
						<img :src="get_image(index, rank)" class="avatar-img" alt="" v-if='get_image(index, rank)'>
						<div class="media-body">
							<strong class='larger'>{{get_name(index, rank)}}</strong>
							<small v-if='get_subtitle_link(index, rank) === null'>{{get_subtitle(index, rank)}}</small>
							<small v-if='get_subtitle_link(index, rank) !== null'><a :href='get_subtitle_link(index, rank)' target='_blank'>{{get_subtitle(index, rank)}} <i class="zwicon-export"></i></a></small>
						</div>
						<div class='rank-num' v-if='race.race_type == "ranked"'>{{(rank+1)}}<span v-if="[1].includes((rank+1) % 10)">st</span><span v-if="[2].includes((rank+1) % 10)">nd</span><span v-if="[3].includes((rank+1) % 10)">rd</span><span v-if="[0, 4, 5, 6, 7, 8, 9].includes((rank+1) % 10)">th</span></div>
					</div>
					<div class="media" v-if='no_votes(index)'> <!-- @TODO - IS EMPTY SELECTIONS & RANKED, OR IS EMPTY LIST AND !RANKED -->
						<div class="avatar-char"><i class="zwicon-close-circle"></i></div>
						<div class="media-body">
							<strong class='larger'>Abstained</strong>
							<small>You have chosen not to vote in this ballot.</small>
						</div>
					</div>
				</div>
				<div>
					<button class='btn btn-sm btn-primary float-right' @click='change_vote(index)'>Change</button>
				</div>
			</div>
		</div>

		<hr class='mt-4 mb-4'/>

		<ErrorMessage :message='submit_error' v-if='submit_error.length > 0' />

		<div class='mt-3'>
			<button class='btn btn-secondary' @click='back'>Start Over</button>
			<button class='btn btn-success float-right' @click='next'>Place Vote!</button>
		</div>
	</div>
</template>

<style scoped lang='scss'>
.ranked-media{
	margin-left: 60px;
	position: relative;

	.rank-num{
		position: absolute;
		left: -60px;
		font-size: 1.8rem;
		width: 50px;
		text-align: center;
	}
}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";

import { SET_RACE, SUBMIT_VOTE } from "@/store/actions.type";

export default {
	name: 'Review',
	components: {
		ErrorMessage
	},
	computed: {
		...mapGetters(['races', 'votes'])
	},
	data(){
		return {
			security: '',
			submit_error: ''
		}
	},
	created() {
        this.$security.get((components) => {
                this.security = this.$security.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
        });
	},
	methods: { 
		back(){
			this.$emit('start_over');
		},
		next(){
			store.dispatch(SUBMIT_VOTE, this.security).then(data => {
				this.$emit('next');
			}).catch(message => {
				this.submit_error = message;
			});
		},
		get_image(race_index, vote_index){
			var race = this.races[race_index];
			var votes = this.votes[race_index];
			var selection = race.candidates.filter(x => x.candidate_id == votes[vote_index]);
			return selection[0].image;
		},
		get_name(race_index, vote_index){
			var race = this.races[race_index];
			var votes = this.votes[race_index];
			var selection = race.candidates.filter(x => x.candidate_id == votes[vote_index]);
			return selection[0].name;
		},
		get_subtitle(race_index, vote_index){
			var race = this.races[race_index];
			var votes = this.votes[race_index];
			var selection = race.candidates.filter(x => x.candidate_id == votes[vote_index]);
			return selection[0].subtitle;
		},
		get_subtitle_link(race_index, vote_index){
			var race = this.races[race_index];
			var votes = this.votes[race_index];
			var selection = race.candidates.filter(x => x.candidate_id == votes[vote_index]);
			return selection[0].subtitle_link;
		},
		no_votes(race_index){
			var race = this.races[race_index];
			var votes = this.votes[race_index];
			if(race.race_type == "ranked") {
				var voted = false;
				for(var i = 0;i<votes.length;i++){
					if(votes[i] != "") voted = true; 
				}
				return !voted;
			} else return (votes.length == 0);
		},
		change_vote(index){
			store.dispatch(SET_RACE, index);
			this.$emit('back', true);
		}
	}
}
</script>