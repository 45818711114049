<template>
	<div class='mb-3 splash-msg fade show'>
		<h2 class='text-info'>{{title || 'Success!'}}</h2>
		<div class='text-info time-item pt-2'>Voting starts in </div><div class='time-item text-info pl-2' v-for='time in times'>{{time.time.toString().padStart(2, '0')}} {{time.text}}s<span class='text-info time-item' v-if='time.id != 4'>,</span></div>
	</div>
</template>

<style lang='scss' scoped>
	.splash-msg{
		font-size: 1.3rem;
	}
	.time-item{
		display: inline-block;
		color: white;
		font-size: 1.2rem;
	}
</style>

<script>
export default {
	name: "InfoSplash",
	props: {
		title: String,
		times: Array
	}
};
</script>