<template>
	<div>
		<div class='row'>
			<div class='col-md-6'>
				<div class='left-side card-body' :class='{"light": lightmode}'>
					<img class='logo-img election-logo pl-0' :src='election.details.logo' v-if='election.details !== undefined && election.details.logo.length > 0'> 

					<h4 class="card-title mt-4">{{election.details.name}}</h4>
					<h6 class="card-subtitle mb-4">{{election.details.description}}</h6>

				</div>
			</div>
			<div class='col-md-6'>
				<div class='right-side card-body'>
					<div class='inv-blokc d-none d-md-block'>
						&nbsp;
					</div>
					
					<InfoSplash  v-if='election.type != "meeting" && election.status == "paused" && !demo_mode' title='Please wait!' message='Voting has been paused while the organizer configures the next round of voting. Please check again shortly.' />
					<InfoSplash v-if='election.status == "new" && !demo_mode' title='Please wait!' message='Voting has not been setup for this election yet.' />
					
					<TimeSplash  v-if='election.status == "pending" && !demo_mode' title='Please wait!' :times='times' />
					<SuccessSplash  v-if='election.status == "finished"' title='Voting Finished!' message='The event has finished and voting is now closed.' />

					<div class='' v-if='((election.type == "meeting" && election.status != "finished") || election.status == "live" || (demo_mode && election.status != "finished"))'>
						<div class="form-group">
							<label :class='{"light": lightmode}'>Secure PIN Code</label>
							<input :class="{'is-invalid': get_error('pin').length > 0}" type="text" name='pin' class="form-control" placeholder='####-####-####' v-model='pin_code'>
							<span v-show="get_error('pin').length > 0" class="text-danger invalid-feedback">{{ get_error('pin') }}</span>
						</div>

						<ErrorMessage v-if='error.length > 0' :message='error' />
					
						<div class='text-center text-md-left'>
							<button class='btn btn-primary' @click='next'>Verify Code</button>
						</div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang='scss'>
	
	.left-side{
		.upper{
			text-transform: uppercase;
		}
		.election-logo{
			max-width: 200px;
		}
		.card-title{
			font-size: 3rem;
		}
		*{
			color: white;
		}
		.grey{
			color: #BBB;
		}

		&.light{
			*{
				color: #000;
			}
		}
	}
	.right-side{
		* {
			color: white;
		}
		.inv-blokc{
			height: 120px;
		}
		label{
			font-size: 1.5rem;
		}
		input{
			background-color: white;
			border: 1px solid #666;
			border-radius: 5px;
			color: #000 !important;
			font-size: 18px;
		}

		*.light{
			color: #000;
		}
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";
import InfoSplash from "@/components/General/InfoSplash";
import SuccessSplash from "@/components/General/SuccessSplash";
import TimeSplash from "@/components/General/TimeSplash";

import { SET_RACE, VALIDATE_CODE, GET_LIVE_BALLOT_LIST } from "@/store/actions.type";

export default {
	name: 'EnterCode',
	components: {
		ErrorMessage,
		InfoSplash,
		SuccessSplash,
		TimeSplash
	},
	data(){
		return {
			errors: {
				pin: ''
			},
			demo_mode: false,
			security: '',
			pin_code: '',
			error: '',
			timeinterval: undefined,
			times: [
				{ id: 1, text: "Day", time: 1 },
				{ id: 2, text: "Hr", time: 1 },
				{ id: 3, text: "Min", time: 1 },
				{ id: 4, text: "Sec", time: 1 }
			]
		};
	},
	created(){
		if(this.$route.query !== undefined){
			if(this.$route.query.demo !== undefined){
				if(this.$route.query.demo == 'true') this.demo_mode = true;
			}
		}
		this.updateTimer();
		this.timeinterval = setInterval(this.updateTimer, 1000);
		this.$security.get((components) => {
                this.security = this.$security.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
        });
	},
	computed: {
		lightmode(){
			return this.branding.lightmode;
		},
		...mapGetters(['election', 'branding'])
	},
	methods: {
		updateTimer: function() {
			if (
				this.times[3].time > 0 ||
				this.times[2].time > 0 ||
				this.times[1].time > 0 ||
				this.times[0].time > 0
			) {
				this.getTimeRemaining();
			} else {
				clearTimeout(this.timeinterval);
			}
		},
		getTimeRemaining: function() {
			let t = Date.parse(new Date(this.election.details.start_time*1000)) - Date.parse(new Date());
			if(t >= 0){
				this.times[3].time = Math.floor(t / 1000 % 60); //seconds
				this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
				this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
				this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
			}else {
				this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
			}
		},
		next(){
			if(this.pin_code.length < 4){
				this.errors.pin = 'Please enter your secure PIN code to vote.';
				return;
			}
			store.dispatch(VALIDATE_CODE, {
				"voter_code": this.pin_code,
				"security": this.security
			}).then(data => {
				if(data.data.voter.voted){
					this.error = "Sorry, this secure PIN # has already been used to vote in this election.";
				} else {
					store.dispatch(GET_LIVE_BALLOT_LIST, { "view_all": true }).then(data => {
						this.$emit('next');
					}).catch(message => {
						console.log(message);
						this.error = message;
					});
				}
			}).catch(message => {
				this.error = message;
			});
		},
		get_error(key){
			if(this.errors[key] !== undefined) return this.errors[key];
			return '';
		},
	}
}
</script>