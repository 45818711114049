<template>
	<!-- Add Voter Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered" v-click-outside="close_choice">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Select Candidate</h5><i class="modal-close zwicon-close pull-right display-4" @click='close_choice'></i>
					</div>
					<div class="modal-body">

						<h5>Selecting rank: {{(selection+1)}}<span v-if="[1].includes((selection+1) % 10)">st</span><span v-if="[2].includes((selection+1) % 10)">nd</span><span v-if="[3].includes((selection+1) % 10)">rd</span><span v-if="[0, 4, 5, 6, 7, 8, 9].includes((selection+1) % 10)">th</span> choice</h5>

						<!--<div class='row mt-3'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Candidate List</label>
									<select class="form-control">
										<option value="">Select Status</option>
										<option :value="candidate.candidate_id" v-for='candidate in options'>{{candidate.name}}</option>
									</select>
									<i class="select-down zwicon-chevron-down display-4"></i>
								</div>
							</div>
						</div>-->

						<div class="widget-profile__list mt-3">
							<a class="media selectable" href='#' :class='{"selected": candidate.candidate_id == current_votes[selection]}' @click.prevent='set_rank(candidate)' v-for='candidate in current_race.candidates'>
								<div class="avatar-char" v-if='!candidate.image'><i class="zwicon-user-circle"></i></div>
								<img :src="candidate.image" class="avatar-img" alt="" v-if='candidate.image'>
								<div class="media-body">
									<strong class='larger'>{{candidate.name}}</strong>
									<small>{{candidate.subtitle}}</small>
								</div>
								<div class="actions listview__actions">
									<i class="selected-icon approval-icon mt-1 zwicon-checkmark-square text-teal"></i>
								</div>
							</a>
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="float-left btn btn-secondary" @click='close_choice'>Cancel</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Add Voter Modal -->
</template>

<style scoped lang='scss'>
	.actions{
		position: relative;

		.selected-icon{
			font-size: 2.5rem;
			position: absolute;
			right: -10px;
		}
	}
	.selected-icon{
		display: none;
	}
	.selectable{
		&:hover, &.selected{
			background-color: rgba(0,0,0,0.1);

			.selected-icon{
				display: block;
			}
		}
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import { SET_RANKED_SELECTION } from "@/store/actions.type";


export default {
	name: 'RankedChoice',
	components: {},
	props: {
		selection: {
			type: Number,
			required: true
		}
	},
	mounted(){
		this.options = [...this.current_race.candidates];
	},
	data(){
		return {
			options: []
		};
	},
	computed: {
		...mapGetters(['current_race', 'current_votes'])
	},
	methods: {
		close_choice(){
			this.$emit('close');
		},
		set_rank(candidate){
			// @TODO - DO STORE STUFF
			store.dispatch(SET_RANKED_SELECTION, {
				"rank": this.selection,
				"candidate_id": candidate.candidate_id
			});
			this.close_choice();
		}
	}
}
</script>