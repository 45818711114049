<template>
	<div>
		<div class="card-body p-0">
			<h3 class="card-title">Available Ballots</h3>
			<h6 class="card-subtitle">
				<label class='mr-2'>Filter by:</label>
				<select class='filter-select' v-model='view_all'>
					<option value="true">View All Ballots</option>
					<option value='false'>Ready to Vote</option>
				</select>
			</h6>

			<div class="actions actions--inverse">
				<div>
					<button class="btn btn-info btn--icon-text text-white" @click='load_ballot_list'><i class="text-white zwicon-refresh-double"></i> Refresh</button>
				</div>
			</div>
		</div>

		<hr class='mt-4 mb-4'/>

		<div class='' v-if='live_status == "finished"'>
			<h5>Voting has concluded for this event.</h5>
		</div>
		<div class='' v-if='live_status != "finished" && races.length == 0'>
			<h5>There are no ballots available at the moment.</h5>
		</div>
		<div class='' v-if='live_status != "finished" && races.length > 0'>
			<h5 class='text-danger' v-if='live_status == "paused"'>Voting has been temporarily paused by event organizers.</h5>
			<h4 v-if='live_status == "live"'>Select a ballot to vote.</h4>

			<div class="widget-profile__list">
				<div v-for='(race, index) in races'>
					<div class="media ballot-item mt-3 mb-0" :class='ballotStatus(race)' @click='view_race(index)'>
						<div class='ballot-label'>
							<span v-if='live_status != "paused" && race.status == "active" && race.revised == 1' class='badge badge-warning'>Ballot Changed</span>
							<span v-if='live_status != "paused" && race.status == "active" && race.revised == 0' class='badge badge-success'>Voted</span>
							<span v-if='live_status != "paused" && race.status == "active" && race.revised === null' class='badge badge-primary'>Voting Open</span>
							<span v-if='live_status == "paused" || race.status == "inactive"' class='badge badge-secondary'>Voting Paused</span>
							<span v-if='live_status != "paused" && race.status == "amending"' class='badge badge-secondary'>Under Amendment</span>
							<span v-if='live_status != "paused" && race.status == "finished"' class='badge badge-success'>Voting Complete</span>
						</div>
						<div class='ballot-label amendment' v-if='race.status == "amending" && race.amendments.length > 0'>
							<span class='badge badge-secondary'>{{race.amendments.length}} Amendment<span v-if='race.amendments.length != 1'>s</span></span>
						</div>
						<div class="media-body text-black">
							<strong class="larger">Ballot: <b>{{race.title}}</b></strong>
							<small>{{race.description}}</small>
						</div>
						<div class="avatar-char mr-0" v-if='live_status != "paused" && race.status == "active"'>
							<i class="approval-icon zwicon-checkmark-square text-teal mt-1" v-if='race.revised == 0'></i>
							<i class="approval-icon zwicon-shape-square text-warning mt-1" v-if='race.revised == 1'></i>
							<i class="approval-icon zwicon-shape-square text-black mt-1" v-if='race.revised === null'></i>
							<i class="approval-icon hover-icon zwicon-arrow-right text-black mt-1" v-if='race.revised == 1 || race.revised === null'></i>
						</div>
						<div class="avatar-char mr-0" v-if='live_status == "paused" || race.status == "inactive"'>
							<i class="approval-icon zwicon-lock text-black mt-1"></i>
						</div>
						<div class="avatar-char mr-0" v-if='live_status != "paused" && race.status == "amending"'>
							<i class="approval-icon zwicon-chevron-down text-black mt-1" v-if='index != race_drawer'></i>
							<i class="approval-icon zwicon-chevron-up text-black mt-1" v-if='index == race_drawer'></i>
						</div>
						<div class="avatar-char mr-0" v-if='live_status != "paused" && race.status == "finished"'>
							<i class="approval-icon zwicon-flag text-teal mt-1"></i>
						</div>
					</div>
					<!-- -->
					<div class='ballot-drawer' v-if='index == race_drawer'>
						<div class="media ballot-item child-ballot mt-0 ml-4 mr-4" v-for='(amend, aindex) in race.amendments' :class='ballotStatus(amend)' @click='view_amendment(index, aindex)'>
							<div class='ballot-label'>
								<span v-if='live_status != "paused" && amend.status == "active" && amend.revised == 1' class='badge badge-warning'>Ballot Changed</span>
								<span v-if='live_status != "paused" && amend.status == "active" && amend.revised == 0' class='badge badge-success'>Voted</span>
								<span v-if='live_status != "paused" && amend.status == "active" && amend.revised === null' class='badge badge-primary'>Voting Open</span>
								<span v-if='live_status == "paused" || amend.status == "inactive"' class='badge badge-secondary'>Voting Paused</span>
								<span v-if='live_status != "paused" && amend.status == "amending"' class='badge badge-secondary'>Under Amendment</span>
								<span v-if='live_status != "paused" && amend.status == "finished"' class='badge badge-success'>Voting Complete</span>
							</div>
							<div class="media-body text-black">
								<strong class="larger">Amendment: <b>{{amend.title}}</b></strong>
								<small>{{amend.description}}</small>
							</div>
							<div class="avatar-char mr-0" v-if='live_status != "paused" && amend.status == "active"'>
								<i class="approval-icon zwicon-checkmark-square text-teal mt-1" v-if='amend.revised == 0'></i>
								<i class="approval-icon zwicon-shape-square text-warning mt-1" v-if='amend.revised == 1'></i>
								<i class="approval-icon zwicon-shape-square text-black mt-1" v-if='amend.revised === null'></i>
								<i class="approval-icon hover-icon zwicon-arrow-right text-black mt-1" v-if='amend.revised == 1 || amend.revised === null'></i>
							</div>
							<div class="avatar-char mr-0" v-if='live_status == "paused" || amend.status == "inactive"'>
								<i class="approval-icon zwicon-lock text-black mt-1"></i>
							</div>
							<div class="avatar-char mr-0" v-if='live_status != "paused" && amend.status == "amending"'>
								<i class="approval-icon zwicon-chevron-down text-black mt-1"></i>
							</div>
							<div class="avatar-char mr-0" v-if='live_status != "paused" && amend.status == "finished"'>
								<i class="approval-icon zwicon-flag text-teal mt-1"></i>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class='mt-4' v-if='false'>
			<button class='btn btn-danger' @click='back'>Back</button>
		</div>
	</div>
</template>

<style lang='scss' scoped>
.filter-select{
	padding: 5px 10px;
	color: black;
}
	.child-ballot{
		border-top: 0px !important;
	}
	.ballot-label{
		position: absolute;
		top: -1px;
		left: -1px;
		height: 20px;
		width: 120px;

		&.amendment{
			left: auto;
			right: -1px !important;

			.badge{
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 0px;
			}
		}

		.badge{
			height: 20px;
			line-height: 20px;
			width: 100%;
			padding: 0rem;
			/*border-bottom-right-radius: 0px;*/
			border-bottom-left-radius: 0px;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}
	.listview__item{
		border: 1px solid rgba(0, 0, 0, 0.15) !important;
    	margin-bottom: 10px;
		.listview__content{
			color: black;
		}
	}
	.ballot-item{
		position: relative;
		padding-top: 30px;
		margin-bottom: 0px;
		&:last-of-type{
			margin-bottom: 10px;
		}
		&.expired, &.incomplete, &.amending{
			&:hover{
				background-color: rgba(0,0,0,0.05) !important;
				cursor: pointer;

				.approval-icon{
					display: none;
				}
				.hover-icon{
					display: block;
				}
			}
		}
	}
	.larger{
		font-size: 1.2rem;
		font-weight: bold;
	}
	.hover-icon{
		display: none;
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";

import { SET_RACE, SET_AMENDMENT_RACE, GET_LIVE_BALLOT_LIST } from "@/store/actions.type";

export default {
	name: 'Voting',
	components: {
		ErrorMessage
	},
	data(){
		return {
			view_all: true,
			security: '',
			error: '',
			race_drawer: -1,
			countdown: 10,
			timer: null
		};
	},
	mounted(){
		this.timer = setInterval(function(){
			this.countdown--;
			if(this.countdown == 0){
				this.countdown = 10;
				this.soft_load();
			}
		}.bind(this), 1000);
	},
	computed: {
		is_first_race(){
			return (this.race_step == 0);
		},
		is_last_race(){
			return ((this.race_step+1) == this.races.length);
		},
		...mapGetters(['election', 'races', 'current_race', 'voter', 'live_status'])
	},
	watch:{
		view_all: function(changed){
			this.load_ballot_list();
		}
	},
	methods: {
		ballotStatus(race){
			if(race.status != "amending" && this.live_status == "paused") return {
				'locked': true
			};
			return {
				'completed': (race.revised == 0 || race.status == "finished"),
				'expired': (race.revised == 1 && race.status == "active"),
				'amending': (race.status == "amending"),
				'incomplete': (race.revised == null && race.status == "active"),
				'locked': (race.status == "inactive")
			}
		},
		view_amendment(race_index, amendment_index){
			if(this.live_status == "paused") return;
			var race = this.races[race_index].amendments[amendment_index];
			if(race.status == "active"){
				if(race.revised == 1 || race.revised === null){
					store.dispatch(SET_AMENDMENT_RACE, amendment_index);
					store.dispatch(SET_RACE, race_index);
				}
			}
		},
		view_race(index){
			store.dispatch(SET_AMENDMENT_RACE, -1);
			var race = this.races[index];
			if(race.status == "amending"){
				// Clicked again, close drawer
				if(index == this.race_drawer) this.race_drawer = -1;
				// Open amendment drawer
				else this.race_drawer = index;
			} else if(race.status == "active"){
				if(this.live_status == "paused") return;
				if(race.revised == 1 || race.revised === null){
					store.dispatch(SET_RACE, index);
				}
			} 
		},
		soft_load(){
			this.$emit('softRefresh', (this.view_all || this.view_all == 'true'));
		},
		load_ballot_list(){
			this.$emit('refresh', (this.view_all || this.view_all == 'true'));
		},
		back(){
			//@TODO wipe session
			this.$emit('back');
		}
	}
}
</script>