<template>
	<div>
		<div class='loadin-screen' v-if='loading_ballots'>
			Loading Ballots...
		</div>
		<RaceList v-if='race_step < 0' @back='back' @softRefresh='soft_refresh_list' @refresh='refresh_list'/>

		<Race v-if='race_step >= 0'/>

		<hr class='mt-4 mb-4' v-if='race_step >= 0'/>

		<ErrorMessage v-if='error.length > 0' :message='error' />

		<div class='mt-3' v-if='race_step >= 0'>
			<button class='btn btn-secondary' @click='last_race'>Back</button>
			<button class='btn btn-success float-right' @click='submit_vote'>Place Vote</button>
		</div>
	</div>
</template>

<style scoped lang='scss'>
	.loadin-screen{
		background-color: rgba(255,255,255,0.9);
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		min-height: 300px;
		font-size: 28px;
		text-align: center;
		z-index: 9;
		padding-top: 50px;
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";
import Race from "@/components/Vote/Race";
import RaceList from "@/components/Vote/RaceList";

import { SET_RACE, SET_AMENDMENT_RACE, SUBMIT_SINGLE_VOTE, GET_LIVE_BALLOT_LIST } from "@/store/actions.type";

export default {
	name: 'Voting',
	components: {
		Race,
		RaceList,
		ErrorMessage
	},
	data(){
		return {
			view_all: 'true',
			error: '',
			security: '',
			loading_ballots: false
		};
	},
	created() {
        this.$security.get((components) => {
			this.security = this.$security.x64hash128(components.map((pair) => { return pair.value }).join(), 31)
        });
	},
	computed: {
		is_first_race(){
			return (this.race_step == 0);
		},
		is_last_race(){
			return ((this.race_step+1) == this.races.length);
		},
		...mapGetters(['election', 'races', 'current_race', 'current_votes', 'voter', 'race_step', 'vote_state', 'votes', 'amendment_votes'])
	},
	methods: {
		last_race(){
			this.error = '';
			store.dispatch(SET_RACE, -1);
			store.dispatch(SET_AMENDMENT_RACE, -1);
		},
		submit_vote(){
			this.error = '';
			store.dispatch(SUBMIT_SINGLE_VOTE, this.security).then(data => {
				this.refresh_list(this.view_all)
			}).catch(message => {
				this.error = message;
			});
		},
		soft_refresh_list(view_all){
			//@TODO
			this.error = '';
			this.view_all = view_all;
			store.dispatch(SET_RACE, -1);
			store.dispatch(SET_AMENDMENT_RACE, -1);
			store.dispatch(GET_LIVE_BALLOT_LIST, { "view_all": this.view_all }).then(data => {
			
			}).catch(message => {
				this.error = message;
			})
		},
		refresh_list(view_all){
			this.loading_ballots = true;
			this.error = '';
			this.view_all = view_all;
			store.dispatch(SET_RACE, -1);
			store.dispatch(SET_AMENDMENT_RACE, -1);
			store.dispatch(GET_LIVE_BALLOT_LIST, { "view_all": this.view_all }).then(data => {
			
			}).catch(message => {
				this.error = message;
			}).finally(data => {
				this.loading_ballots = false;
			});
		},
		back(){
			//wipe session
			this.error = '';
			this.$emit('back');
		}
	}
}
</script>