<template>
	<div>
		<RankedChoice :selection='current_choice' v-if='show_rank_modal' @close='close_choice'/>


		<h4 class="card-title"><span>Ballot: </span>{{current_race.title}}<span class='float-right' style='text-transform: capitalize;'>Type: {{current_race.race_type}}</span></h4>
		<h6 class="card-subtitle mb-4">{{current_race.description}}</h6>

		<hr class='mt-4 mb-4'/>

		<!--<h3>Winners: {{current_race.winner_count}} | Selections: {{current_race.selection_count}} | Vote Type: {{current_race.race_type}}</h3>-->
		<h4 v-if='current_race.selection_count == 1 && current_race.race_type != "ranked"'>Please select your desired <span v-if='current_race.race_type == "referendum"'>choice</span><span v-if='current_race.race_type != "referendum"'>candidate</span>.</h4>
		<h4 v-if='current_race.selection_count > 1 && current_race.race_type != "ranked"'>Please select your top {{current_race.selection_count}} <span v-if='current_race.race_type == "referendum"'>choices</span><span v-if='current_race.race_type != "referendum"'>candidates</span>.</h4>
		<h4 v-if='current_race.race_type == "ranked"'>Please select up to {{current_race.selection_count}} <span v-if='current_race.race_type == "referendum"'>choices</span><span v-if='current_race.race_type != "referendum"'>candidates</span> in order of preference.</h4>

		<div class="widget-profile__list mt-3" v-if='current_race.race_type != "ranked"'>
			<div class="media" v-for='candidate in current_race.candidates'>
				<div class="avatar-char" v-if='!candidate.image'><i class="zwicon-user-circle"></i></div>
				<img :src="candidate.image" class="avatar-img" alt="" v-if='candidate.image'>
				<div class="media-body">
					<strong class='larger'>{{candidate.name}}</strong>
					<small v-if='candidate.subtitle_link !== null'><a :href='candidate.subtitle_link' target='_blank'>{{candidate.subtitle}} <i class="zwicon-export"></i></a></small>
					<small v-if='candidate.subtitle_link === null'>{{candidate.subtitle}}</small>
				</div>
				<div class="actions listview__actions">
					<i 
						class="approval-icon mt-1" 
						:class='{
							"zwicon-checkmark-square text-teal": current_votes.includes(candidate.candidate_id),
							"zwicon-shape-square": !current_votes.includes(candidate.candidate_id)
						}' 
						@click='toggle_selection(candidate)'></i>
				</div>
			</div>
			<div class="media" v-if='current_race.allow_abstain'>
				<div class="avatar-char"><i class="zwicon-close-circle"></i></div>
				<div class="media-body">
					<strong class='larger'>Abstain</strong>
					<small>None of the above choices.</small>
				</div>
				<div class="actions listview__actions">
					<i 
						class="approval-icon mt-1" 
						:class='{
							"zwicon-checkmark-square text-teal": current_votes.length == 0,
							"zwicon-shape-square": current_votes.length > 0
						}' 
						@click='abstain()'></i>
				</div>
			</div>
		</div>
		<div class="widget-profile__list mt-3" v-if='current_race.race_type == "ranked"'>
			<div class="media ranked-media" :class='{"has-choice": option != ""}' v-for='(option, index) in current_votes'>
				<div class="avatar-char" v-if='!get_image(option)'><i class="zwicon-user-circle"></i></div>
				<img :src="get_image(option)" class="avatar-img" alt="" v-if='get_image(option)'>
				<div class="media-body">
					<strong class='larger'>{{get_name(option)}}</strong>
					<small>{{get_subtitle(option)}}</small>
				</div>
				<div class="actions listview__actions mt-3">
					<a class='btn btn-sm btn-primary text-white' @click='open_choice(index)'>Select</a>
				</div>
				<div class='rank-num'>{{(index+1)}}<span v-if="[1].includes((index+1) % 10)">st</span><span v-if="[2].includes((index+1) % 10)">nd</span><span v-if="[3].includes((index+1) % 10)">rd</span><span v-if="[0, 4, 5, 6, 7, 8, 9].includes((index+1) % 10)">th</span></div>
			</div>
			<div class="media ranked-media" v-if='current_race.allow_abstain'>
				<div class="avatar-char"><i class="zwicon-close-circle"></i></div>
				<div class="media-body">
					<strong class='larger'>Abstain</strong>
					<small>None of the above choices.</small>
				</div>
				<div class="actions listview__actions">
					<i 
						class="approval-icon mt-1" 
						:class='{
							"zwicon-checkmark-square text-teal": (current_votes.filter(x => x != "").length == 0),
							"zwicon-shape-square": (current_votes.filter(x => x != "").length > 0)
						}' 
						@click='abstain()'></i>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang='scss'>
	.media{
		padding-right: 75px !important;
		position: relative;
	}
	.card > .actions, .card-body .actions{
		right: 25px;
		top: 9px;

		i{
			font-size: 40px;
			cursor: pointer;
		}
	}
	.ranked-media{
		margin-left: 60px;

		.rank-num{
			position: absolute;
			left: -60px;
			font-size: 1.8rem;
			width: 50px;
    		text-align: center;
		}
	}
	.has-choice{
		background-color: rgba(40, 167, 69, 0.2) !important;
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import RankedChoice from "@/components/Vote/RankedChoice";

import { REMOVE_RACE_SELECTION, ADD_RACE_SELECTION, CLEAR_SELECTIONS } from "@/store/actions.type";

export default {
	name: 'Race',
	components: {
		RankedChoice
	},
	data(){
		return {
			race_step: 0,
			show_rank_modal: false,
			current_choice: -1,
			select_new: ''
		};
	},
	computed: {
		empty_list(){
			console.log(this.current_votes.filter(x => x != ''));
			return (this.current_votes.filter(x => x != '').length == 0);
		},
		...mapGetters(['election', 'current_race', 'current_votes'])
	},
	methods: {
		open_choice(index){
			// open Modal
			this.current_choice = index;
			this.select_new = this.current_votes[index];
			this.show_rank_modal = true;
		},
		set_rank(){
			//@TODO set rank (select_new)
			this.show_rank_modal = false;
		},
		close_choice(){
			this.show_rank_modal = false;
		},
		get_image(option){
			if(option == '') return null;
			var selection = this.current_race.candidates.filter(x => x.candidate_id == option);
			return selection[0].image;
		},
		get_name(option){
			if(option == '') return 'Select Choice';
			var selection = this.current_race.candidates.filter(x => x.candidate_id == option);
			return selection[0].name;
		},
		get_subtitle(option){
			if(option == '') return 'Select a candidate to fill this spot.';
			var selection = this.current_race.candidates.filter(x => x.candidate_id == option);
			return selection[0].subtitle;
		},
		toggle_selection(candidate){
			if(this.current_votes.includes(candidate.candidate_id)){
				// remove item from array
				store.dispatch(REMOVE_RACE_SELECTION, candidate.candidate_id);
			} else {
				// Add selection to list
				store.dispatch(ADD_RACE_SELECTION, candidate.candidate_id);
			}
		},
		abstain(){
			if(this.current_race.race_type != 'ranked' && this.current_votes.length == 0) return;
			store.dispatch(CLEAR_SELECTIONS);
		},
		clear_ranks(){}
	}
}
</script>