<template>
	<div>
		<h4 class="card-title">Voting Details</h4>
		<h6 class="card-subtitle mb-4">Please review and confirm your information.</h6>

		<div class="widget-profile__list">
			<div class="media">
				<div class="avatar-char"><i class="zwicon-user-circle"></i></div>
				<div class="media-body">
					<strong class='larger'><b v-if='voter.proxy'>Proxy for: </b>{{voter.full_name}}</strong>
					<small>Name</small>
				</div>
			</div>
			<div class="media" v-if='!voter.proxy'>
				<div class="avatar-char"><i class="zwicon-mail"></i></div>
				<div class="media-body">
					<strong class='larger'>{{voter.email}}</strong>
					<small>Email</small>
				</div>
			</div>
			<div class="media" v-if='election.config.weighted_voting'>
				<div class="avatar-char"><i class="zwicon-user-interview"></i></div>
				<div class="media-body">
					<strong class='larger'>{{voter.weight || '1'}}</strong>
					<small>Vote Weight</small>
				</div>
			</div>
			<div class="media" v-if='election.type != "meeting"'>
				<div class="avatar-char"><i class="zwicon-close-square"></i></div>
				<div class="media-body">
					<strong class='larger'>{{races.length}}</strong>
					<small>Available Ballots</small>
				</div>
			</div>

			<div class="row mt-4 mb-3">
				<div class='col-md-12'>
					<div class="btn-group btn-group-toggle btn-group--colors" data-toggle="buttons">
						<label class="btn" :class='{"active bg-teal": agreement }'><input type="checkbox" autocomplete="off" v-model='agreement'></label>
						<div class='ml-1 mt-2'>I have reviewed and agree to {{branding.platform_name}}'s <a class='primary-link' :href='branding.privacy_policy' target='_blank'>Privacy Policy</a>.</div> 
					</div>
				</div>
			</div>

			<ErrorMessage style='mt-3' :message='error_msg' v-if='error_msg.length > 0'/>

			<div class="p-3"></div>
		</div>

		<div class=''>
			<button class='btn btn-danger' @click='back'>Log Out</button>
			<button class='btn btn-primary float-right' @click='next'>Begin Vote</button>
		</div>
	</div>
</template>

<style scoped>
	.primary-link{
		color: #DC1F26 !important;
		text-decoration: underline;
	}
	.larger{
		font-size: 1.2rem;
		font-weight: bold;
	}
</style>

<script>
import { mapGetters } from 'vuex';
import store from "@/store";

import ErrorMessage from "@/components/General/ErrorMessage";

import { SET_RACE, CLEAR_SELECTIONS } from "@/store/actions.type";

export default {
	name: 'Preamble',
	components: {
		ErrorMessage
	},
	computed: {
		...mapGetters(['election', 'voter', 'races', 'branding'])
	},
	data(){
		return {
			'agreement': false,
			'error_msg': ''
		}
	},
	methods: {
		back(){
			//@TODO wipe session
			this.$emit('back');
		},
		next(){
			this.error_msg = '';
			if(!this.agreement){
				this.error_msg = 'Please agree to the terms and conditions to continue with placing your vote.';
				return;
			}
			for(var i=0;i<this.races.length;i++){
				store.dispatch(SET_RACE, i);
				store.dispatch(CLEAR_SELECTIONS);
			}
			if(this.election.type != "meeting") {
				store.dispatch(SET_RACE, 0);
			} else {
				store.dispatch(SET_RACE, -1);
			}
						
			this.$emit('next');
		}
	}
}
</script>